import React from "react";
import AccordionBase from "../../../utils/Accordion/AccordionBase";
import FilterList, { Filter } from "./FilterList";
import { CheckboxProps } from "components/base/Checkbox/Checkbox";

export interface FilterVerticalProps {
  closeLabel?: string;
  heading: string;
  openLabel?: string;
  id: string;
  filters: Filter[];
  handleFilterGroupChange?: (
    updatedFilterGroupId: string,
    updatedFilterGroup: CheckboxProps[]
  ) => void;
}

const FilterVertical: React.FC<FilterVerticalProps> = ({
  openLabel,
  closeLabel,
  heading,
  id,
  filters,
  handleFilterGroupChange = () => {},
}) => {
  const handleFilterChange = (checkboxId: string, checked: boolean) => {
    const updatedFilterGroup = (function getUpdatedFilterGroup(
      filters: Filter[]
    ): Filter[] {
      return filters.map(checkbox => {
        if (checkbox.id === checkboxId) {
          return { ...checkbox, checked };
        }
        if (checkbox.nestedFilters) {
          return {
            ...checkbox,
            nestedFilters: getUpdatedFilterGroup(checkbox.nestedFilters),
          };
        }
        return checkbox;
      });
    })(filters);

    handleFilterGroupChange(id, updatedFilterGroup);
  };

  return (
    <AccordionBase
      {...{ closeLabel, heading, openLabel, theme: "secondary" }}
      isGlobal
    >
      <FilterList filters={filters} handleFilterChange={handleFilterChange} />
    </AccordionBase>
  );
};

export default FilterVertical;
