import styles from "./NewsPage.module.scss";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import NewsPageHeroBlock from "components/blocks/NewsPageHeroBlock/NewsPageHeroBlock";
import { NewsPageHeroBlockProps } from "components/blocks/NewsPageHeroBlock/NewsPageHeroBlock";
import CardListBlock, { CardListBlockProps } from "components/blocks/CardListBlock/CardListBlock";
import GridContainer from "components/layout/Grid/GridContainer";
import GridItem from "components/layout/Grid/GridItem";

export interface NewsPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: NewsPageHeroBlockProps;
  relatedArticles?: CardListBlockProps;
}

const NewsPage: React.FC<NewsPageProps> = ({
  contentAnchors,
  contentArea,
  hero,
  relatedArticles
}) => {
  return (
    <main className={styles.newsPage}>
      {hero && <NewsPageHeroBlock {...hero} />}
      <TopPageBlocks contentAnchors={contentAnchors} />
      {contentArea && <ContentArea {...contentArea} />}
      {relatedArticles && (
        <GridContainer>
          <GridItem>
            <CardListBlock {...relatedArticles} />
          </GridItem>
        </GridContainer>
      )}
    </main>
  );
};

export default NewsPage;
