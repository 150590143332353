import classNames from "classnames";
import styles from "./CardListBlock.module.scss";
import { LinkProps } from "src/base-props/LinkProps";
import CTA from "../../base/CTA/CTA";
import GlobalCardBlock, {
  GlobalCardBlockProps,
} from "../GlobalCardBlock/GlobalCardBlock";
import { OverrideTheme } from "SiteComponents";

export interface CardListBlockProps {
  link: LinkProps;
  listItems: GlobalCardBlockProps[];
  title: string;
  type?: "ReportPage" | "InfoPage" | "EventPage" | "TilskuddPage";
  theme?: "bold" | "moderate" | "outlined" | "soft" | "vivid";
}

const CardListBlock: React.FC<CardListBlockProps> = ({
  link,
  listItems,
  title,
  type,
  theme,
}) => {
  return (
    <div className={styles.cardListBlock}>
      <div className={styles.cardListBlockText}>
        {title && <h2>{title}</h2>}
        {link && (
          <OverrideTheme theme="light">
            <CTA theme="tertiary" size="small" {...link} />
          </OverrideTheme>
        )}
      </div>

      {listItems.length > 0 && (
        <ul
          className={classNames({ [styles.isMedium]: type === "TilskuddPage" })}
        >
          {listItems.map((card, index) => (
            <li key={index}>
              <GlobalCardBlock
                isMedium={type === "TilskuddPage" ? true : false}
                theme={theme}
                {...card}
                type={type}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CardListBlock;
