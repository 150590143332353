import IconMS from "src/components/utils/IconMS/IconMS";
import styles from "./DownloadLinkListBlock.module.scss";
import Tag from "components/base/Tag/Tag";

export interface DownloadLinkListItem {
  title: string;
  url: string;
  description?: string;
  mediaType?: string;
  mediaSize?: string;
}
export interface DownloadLinkListBlockProps {
  downloadLinkListItems: DownloadLinkListItem[];
}

const isPDF = (url: string) => url.includes("pdf");

const DownloadLinkListBlock: React.FC<DownloadLinkListBlockProps> = ({
  downloadLinkListItems,
}) => {
  if (!downloadLinkListItems) return null;
  return (
    <ul className={styles.downloadLinkListBlock}>
      {downloadLinkListItems.map(
        ({ title, url, description, mediaType, mediaSize }) => (
          <li className={styles.linkItem} key={title}>
            {url && title && (
              <>
                <IconMS className={styles.icon} name="download" />
                <a
                  className={styles.link}
                  href={url}
                  download={isPDF(url) ? undefined : title}
                  target={isPDF(url) ? "_blank" : undefined}
                  rel={isPDF(url) ? "noopener noreferrer" : undefined}
                >
                  <h3>{title}</h3>
                </a>
              </>
            )}
            {description && <p>{description}</p>}
            {mediaType && mediaSize && (
              <Tag text={`${mediaType}, ${mediaSize}`}/>
            )}
          </li>
        )
      )}
    </ul>
  );
};
export default DownloadLinkListBlock;
