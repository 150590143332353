import React from "react";
import styles from "./CTA.module.scss";
import classNames from "classnames";
import IconMS from "src/components/utils/IconMS/IconMS";
import { LinkProps } from "src/base-props/LinkProps";
import WithActionClasses, {
  WithActionClassesProps,
} from "components/HoC/WithActionClasses/WithActionClasses";

export interface BasicCTAProps extends LinkProps {
  className?: string;
  showIcon?: boolean;
}

export interface CTAProps extends BasicCTAProps, WithActionClassesProps {}

const CTA: React.FC<CTAProps> = ({
  text,
  description,
  className,
  target,
  url,
  externalLinkDomain,
  showIcon = true,
}) => {
  if (!url) return null;

  const isExternal = externalLinkDomain && externalLinkDomain.length > 0;
  return (
    <a title={description ? description : text}
      className={classNames(styles.CTA, className)}
      href={url}
      target={isExternal ? "_blank" : target}
      rel={isExternal ? "noopener noreferrer" : undefined}
    >
      <p className={styles.text}>{text}</p>
      {showIcon && <IconMS name={isExternal ? "open_in_new" : "east"} />}
    </a>
  );
};

export const BasicCTA = CTA;
export default WithActionClasses(CTA);
