import styles from "./LargeLinkListBlock.module.scss";
import classNames from "classnames";
import useSelectTheme from "../../../utils/useSelectTheme";
import { LinkProps } from "src/base-props/LinkProps";
import { Icon } from "SiteComponents";
export interface LargeLinkListBlockProps {
  heading: string;
  linkListItems: LinkProps[];
}

export const LargeLinkListBlock: React.FC<LargeLinkListBlockProps> = ({
  heading,
  linkListItems,
}) => {
  const selectedTheme = useSelectTheme("", "blue");

  return (
    <div
      className={classNames(
        styles.largeLinkListBlock,
        styles[selectedTheme],
        {}
      )}
    >
      {heading && <h2>{heading}</h2>}
      <ul className={styles.listContainer}>
        {linkListItems?.map(link => (
          <li key={link.text}>
            <a href={link.url} target={link.target}>
              {link.text}
              <Icon className={styles.icon} name="arrow-right" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LargeLinkListBlock;
