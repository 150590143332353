import React, { useState } from "react";
import styles from "./FilterBlock.module.scss";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";

import { FilterVertical } from "SiteComponents";
import { FilterVerticalProps } from "./FilterVertical/FilterVertical";
import { CheckboxProps } from "components/base/Checkbox/Checkbox";
import FilterListContainer from "./FilterListContainer/FilterListContainer";
import { Filter } from "./FilterVertical/FilterList";

export interface FilterBlockProps extends ReactComponentPropsBase {
  filterGroupList: FilterVerticalProps[];
  heading: string;
  clearLabel?: string;
  showLabel?: string;
}

const FilterBlock: React.FC<FilterBlockProps> = ({
  filterGroupList,
  heading,
  clearLabel,
  showLabel,
}) => {
  const [filterState, setFilterState] =
    useState<FilterVerticalProps[]>(filterGroupList);
  const [results, setResults] = useState<any[]>([]);

  const fetchResults = async (filters: any) => {
    // Fetch new results from the server
    // Update filterState with new results
  };

  const handleShowResults = async () => {
    // Show results
    // const results = await fetchResults(filterState);
    // setResults(results);
  };

  const handleFilterGroupChange = (
    updatedFilterGroupId: string,
    updatedFilters: CheckboxProps[]
  ) => {
    let didUpdateFilterState = false;

    const updatedFilterState = filterState.map(filterGroup => {
      if (filterGroup.id === updatedFilterGroupId) {
        didUpdateFilterState = true;
        return { ...filterGroup, filters: updatedFilters };
      }
      return filterGroup;
    });

    if (didUpdateFilterState) {
      // Request new results from the server
      // Recieve new filterState from server as well? in case something goes wrong BE can alter the state?
      fetchResults(updatedFilterState);
      setFilterState(updatedFilterState);
    }
  };

  const clearAllFilters = () => {
    const clearedFilterGroupList = filterState.map(filterGroup => {
      return {
        ...filterGroup,
        filters: (function getClearedFilters(filters: Filter[]): Filter[] {
          return filters.map(filter => ({
            ...filter,
            checked: false,
            nestedFilters: getClearedFilters(filter.nestedFilters || []),
          }));
        })(filterGroup.filters),
      };
    });

    setFilterState(clearedFilterGroupList);
    fetchResults(clearedFilterGroupList);
  };

  return (
    <BlockContainer className={styles.filterBlock} verticalPadding>
      <FilterListContainer
        title={heading}
        handleClearAll={clearAllFilters}
        clearLabel={clearLabel}
        showLabel={showLabel}
        handleShowResults={handleShowResults}
      >
        <ul className={styles.filterList}>
          {filterState?.map(filterGroup => {
            return (
              <li>
                <FilterVertical
                  {...filterGroup}
                  filters={filterGroup.filters}
                  handleFilterGroupChange={handleFilterGroupChange}
                  key={filterGroup.id}
                />
              </li>
            );
          })}
        </ul>
      </FilterListContainer>
      {/* render results */}
    </BlockContainer>
  );
};

export default FilterBlock;
