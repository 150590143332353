import Image, { ImageProps } from "components/base/Image/Image";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import { IconMS, RichTextBlock } from "SiteComponents";
import { LinkProps } from "src/base-props/LinkProps";
import styles from "./ContactBlockItem.module.scss";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "src/utils/useWindowSize";

export interface ContactBlockItemProps {
  name: string;
  subHeading?: string;
  image?: ImageProps;
  ingress?: string;
  email?: string;
  phoneNumber?: string;
  link?: LinkProps;
  small?: boolean;
  horizontal?: boolean;
  text?: ContentAreaProps;
  showMoreText?: string;
  showLessText?: string;
}

const ContactBlockItem = ({
  name,
  subHeading,
  image,
  ingress,
  email,
  phoneNumber,
  link,
  text,
  small,
  horizontal,
  showLessText,
  showMoreText,
}: ContactBlockItemProps) => {
  const richTextRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number>();
  const [showButton, setShowButton] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const { width } = useWindowSize();

  useEffect(() => {
    if (richTextRef.current) {
      if (richTextRef.current.scrollHeight > 66) {
        setExpanded(false);
        setShowButton(true);
      } else {
        setExpanded(true);
        setShowButton(false);
      }
    }
  }, [width]);

  const handleClick = () => {
    setExpanded(prev => !prev);
  };

  useEffect(() => {
    if (richTextRef.current) {
      expanded
        ? setMaxHeight(richTextRef.current.scrollHeight)
        : setMaxHeight(42);
    }
  }, [expanded]);

  return (
    <div
      className={classNames(
        { [styles.small]: small, [styles.horizontal]: horizontal },
        styles.contactBlockItem
      )}
    >
      {image && (
        <div className={styles.image}>
          <Image src={image.src} alt={image.alt} />
        </div>
      )}
      <div>
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        <h3>{name}</h3>
        {ingress && <p className={styles.ingress}>{ingress}</p>}
        <ul>
          {email && (
            <li>
              <a href={`mailto:${email}`}>{email}</a>
            </li>
          )}
          {phoneNumber && (
            <li>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </li>
          )}
          {link?.url && (
            <li className={styles.link}>
              <a href={link.url}>
                <span>{link.text}</span>
              </a>
              <IconMS
                className={styles.linkIcon}
                name="open_in_new"
                size="16px"
              />
            </li>
          )}
        </ul>
        {text && (
          <div
            className={styles.richText}
            ref={richTextRef}
            style={
              expanded
                ? { maxHeight: "unset", marginBottom: 0 }
                : // @ts-ignore: CSS variable
                  { "--max-height": maxHeight + "px", marginBottom: "24px" }
            }
          >
            <RichTextBlock text={text} />
          </div>
        )}
        {showButton && (
          <button className={styles.button} onClick={handleClick}>
            {expanded ? showLessText : showMoreText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ContactBlockItem;
