import styles from "./EventPage.module.scss";

import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import EventPageHeroBlock from "components/blocks/EventPageHeroBlock/EventPageHeroBlock";
import { EventPageHeroBlockProps } from "components/blocks/EventPageHeroBlock/EventPageHeroBlock";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import AccordionItemBlock, {
  AccordionItemBlockProps,
} from "components/blocks/AccordionItemBlock/AccordionItemBlock";
import { GridContainer, GridItem } from "SiteComponents";

export interface EventPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: EventPageHeroBlockProps;
  program?: AccordionItemBlockProps;
}

const EventPage: React.FC<EventPageProps> = ({
  contentArea,
  hero,
  mainIntro,
  contentAnchors,
  program,
}) => {
  return (
    <main className={styles.eventPage}>
      {hero && <EventPageHeroBlock {...hero} />}
      <TopPageBlocks contentAnchors={contentAnchors} mainIntro={mainIntro} />
      {contentArea && <ContentArea {...contentArea} />}
      <GridContainer spacing={0}>
        <GridItem xs={12} lg={6} lgOffset={3}>
          {program && <AccordionItemBlock {...program} />}
          </GridItem>
      </GridContainer>
    </main>
  );
};

export default EventPage;
