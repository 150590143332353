import React, { useState } from "react";
import styles from "./FilterListContainer.module.scss";
import { Button } from "SiteComponents";
import useWindowSize from "src/utils/useWindowSize";
import classNames from "classnames";

export interface FilterListContainerProps {
  children: React.ReactNode;
  title?: string;
  handleClearAll: () => void;
  clearLabel?: string;
  showLabel?: string;
  handleShowResults: () => void;
}

const FilterListContainer: React.FC<FilterListContainerProps> = ({
  children,
  title,
  handleClearAll,
  clearLabel,
  showLabel,
  handleShowResults,
}) => {
  const [openFilters, setOpenFilters] = useState(false);
  const isDesktop = useWindowSize().width > 768;

  if (isDesktop) return children;

  const handleOpenFilters = () => {
    setOpenFilters(true);
  };

  const onShowResults = () => {
    setOpenFilters(false);
    handleShowResults();
  };

  return (
    <div
      className={classNames(styles.filterListContainer, {
        [styles.showMobileFilters]: openFilters,
      })}
    >
      {!openFilters && (
        <Button
          onClick={handleOpenFilters}
          className={styles.openFiltersButton}
          buttonText="Show Filters"
          theme="primary"
        />
      )}

      {openFilters && (
        <>
          <div className={styles.header}>
            {title && <h2 className={styles.heading}>{title}</h2>}
            <Button
              onClick={handleClearAll}
              className={styles.clearAllButton}
              buttonText={clearLabel}
              theme="tertiary"
            />
          </div>
          <div className={styles.filterList}>{children}</div>
          <div className={styles.filterAction}>
            <Button
              onClick={onShowResults}
              className={styles.showResultsButton}
              buttonText={showLabel}
              theme="primary"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FilterListContainer;
