import styles from "./TilskuddPage.module.scss";
import classname from "classnames";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import TilskuddPageHero from "components/blocks/TilskuddPageHeroBlock/TilskuddPageHeroBlock";
import { TilskuddPageHeroBlockProps } from "components/blocks/TilskuddPageHeroBlock/TilskuddPageHeroBlock";

export interface TilskuddPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  hero: TilskuddPageHeroBlockProps;
}

const TilskuddPage: React.FC<TilskuddPageProps> = ({
  contentArea,
  hero,
  contentAnchors,
}) => {
  return (
    <main className={styles.tilskuddPage}>
      {hero && <TilskuddPageHero {...hero} />}
      <TopPageBlocks contentAnchors={contentAnchors} />
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default TilskuddPage;
