import React from "react";
import styles from "./Checkbox.module.scss";
import classNames from "classnames";
import { IconMS } from "SiteComponents";

export interface CheckboxProps {
  value: string;
  label: string;
  hitCount?: string;
  id: string;
  handleChange?: (id: string, checked: boolean) => void;
  checked?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  value,
  label,
  id,
  hitCount,
  handleChange = () => {},
  checked = false,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      handleChange(id, !checked);
    }
  };

  return (
    <label
      htmlFor={id}
      className={classNames(styles.checkbox, {
        [styles.checked]: checked,
      })}
    >
      <input
        id={id}
        className={styles.checkboxInput}
        onChange={() => handleChange(id, !checked)}
        value={value}
        type="checkbox"
        checked={checked}
        tabIndex={-1}
        aria-hidden="true"
      />
      <span
        className={styles.checkboxCustom}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        role="checkbox"
        aria-checked={checked}
      >
        {checked && <IconMS name="check" size={"20px"} />}
      </span>
      <span className={styles.labelText}>{label}</span>
      {hitCount && <span className={styles.hitCount}>{hitCount}</span>}
    </label>
  );
};

export default Checkbox;
