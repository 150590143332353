import classNames from "classnames";
import styles from "./GlobalCardBlock.module.scss";
import Media from "components/utils/Media/Media";
import IconMS from "../../utils/IconMS/IconMS";
import Tag, { TagProps } from "components/base/Tag/Tag";
import { ImageProps } from "components/base/Image/Image";
import { LinkProps } from "src/base-props/LinkProps";

export interface GlobalCardBlockProps {
  icon?: string;
  image?: ImageProps;
  ingress?: string;
  isMedium?: boolean;
  subHeading?: string;
  theme?: "bold" | "moderate" | "outlined" | "soft" | "vivid";
  tags?: TagProps[];
  title: string;
  link?: LinkProps;
  type?: "ReportPage" | "InfoPage" | "EventPage" | "TilskuddPage";
}

const GlobalCardBlock = ({
  icon,
  image,
  ingress,
  isMedium,
  subHeading,
  theme = "moderate",
  tags,
  title,
  link,
  type,
}: GlobalCardBlockProps) => {
  return (
    <a
      href={link?.url}
      target={link?.target}
      className={classNames(styles.cardBlock, {
        [styles[theme]]: theme,
        [styles.isMedium]: isMedium,
      })}
    >
      <div
        className={classNames(styles.cardBlockMedia, {
          [styles.withIcon]: icon,
        })}
      >
        {icon && <IconMS className={styles.icon} name={icon} size="48px" />}
        {image && (
          <Media
            className={classNames({ [styles.medium]: isMedium })}
            image={{
              ...image,
              ratio: type === "ReportPage" ? "medium" : "standard",
            }}
          />
        )}
      </div>
      <div className={styles.cardBlockText}>
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
          <h3>
            {title}
            {link?.target && (
                <IconMS size="18px" className={styles.linkIcon} name={"open_in_new"} />
            )}
          </h3>
        {ingress && <p className={styles.ingress}>{ingress}</p>}
        {tags && (
          <ul className={styles.tagList}>
            {tags
              .map((tag, index) => (
                <li key={index}>
                  {index < 2 ? (
                    <Tag {...tag} className={styles.tag} />
                  ) : (
                    <Tag text={`+${index - 1}`} className={styles.numberTag} />
                  )}
                </li>
              ))
              .slice(0, 3)}
          </ul>
        )}
      </div>
    </a>
  );
};

export default GlobalCardBlock;
