import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import styles from "./ProgramItemBlock.module.scss";

export interface ProgramItemBlockProps {
  descriptiveTitle?: string;
  richText?: ContentAreaProps;
  time?: string;
}

const ProgramItemBlock: React.FC<ProgramItemBlockProps> = ({
  descriptiveTitle,
  richText,
  time,
}) => {
  return (
    <div className={styles.programItemBlock}>
      <div className={styles.textContainer}>
        <p>
          <time dateTime={time}>{time}</time>
          {descriptiveTitle}
        </p>
      </div>
      <div className={styles.richText}>
        {richText && <ContentArea isInsideBlock {...richText} />}
      </div>
    </div>
  );
};

export default ProgramItemBlock;
