import GridContext, { Spacing } from "./GridContext";
import classNames from "classnames";
import styles from "./GridContainer.module.scss";

export interface GridContainerStyleProps {
  spacing?: Spacing;
  fullWidth?: boolean;
  verticalPadding?: boolean;
  noVertical?: boolean;
  noHorizontal?: boolean;
  horizontalSpacing?: "md" | "lg";
  className?: string;
  id?: string;
  noTopMargin?: boolean;
}

interface GridContainerProps extends GridContainerStyleProps {
  children: React.ReactNode;
}

const GridContainer: React.FC<GridContainerProps> = ({
  children,
  spacing = { xs: 12 },
  fullWidth = false,
  verticalPadding = false,
  noVertical,
  noHorizontal,
  className,
  id,
  horizontalSpacing = "md",
  noTopMargin = false,
}) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexWrap: "wrap",
    // Adjust margin based on the spacing prop
    margin:
      typeof spacing === "number"
        ? `-${spacing * 8}px`
        : `-${spacing.xs && spacing.xs * 8}px`, // Default to xs spacing
    // Include media queries for responsive spacing if needed
  };
  return (
    <GridContext.Provider value={spacing}>
      <div
        className={classNames(styles.gridContainer, {
          [styles.fullWidth]: fullWidth,
          [styles.verticalPadding]: verticalPadding,
          [styles.noVertical]: noVertical,
          [styles.noHorizontal]: noHorizontal,
          [styles.horizontalSpacingLarge]: horizontalSpacing === "lg",
          [styles.noTopMargin]: noTopMargin,
        })}
        id={id}
      >
        <div className={classNames(className)} style={containerStyle}>
          {children}
        </div>
      </div>
    </GridContext.Provider>
  );
};

export default GridContainer;
